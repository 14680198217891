@tailwind base;
@tailwind components;
@tailwind utilities;

.mainBox {
  @apply flex flex-col items-center justify-center;
}

.whiteBox {
  @apply w-full bg-white p-4 shadow-md rounded-lg mx-auto;
}
